:root {
  --background-color: #f9f9f9;
  --text-color: #161616;
  --secondary-background-color: #ebebeb;
  --edited-color: #238ed0;
  --error-color: #e23636;
  --not-set-color: #bcbcbc;
}

* {
  font-family: Nunito, Helvetica, sans-serif, Arial;
  color: var(--text-color);
}

body {
  margin: 0;
  background-color: var(--background-color);
}

/*Scrollbar is above all content*/
html {
  overflow-y: scroll;
}

.text-input {
  text-align: right;
  border: none;
  outline: none;
  box-sizing: border-box;
  border-bottom: 2px solid var(--text-color);
  background-color: transparent;
  margin-right: 0px;
  margin-left: 0px;
}

.standard-button {
  background-color: var(--background-color);
  border: 2px solid var(--text-color);
  padding: 5px;
  margin: 5px;
}

.standard-button:hover {
  cursor: pointer;
  background-color: var(--secondary-background-color);
}

.link:hover {
  color: gray;
  cursor: pointer;
}

.link {
  font-size: large;
  user-select: none;
}

main {
  margin: 10px;
}

.triple-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

@media only screen and (max-width: 360px) {
  body {
    width: 360px;
    overflow: auto;
  }
}

@media only screen and (max-width: 600px) {
  .only-desktop {
    display: none;
  }

  .triple-grid.column-on-mobile {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }

  .triple-grid.column-on-mobile.left-first {
    flex-direction: column;
  }
}

/*
.triple-grid > div {
  border: 1px solid red;
}
*/

.triple-grid > .left {
  display: flex;
  justify-content: right;
  align-items: center;
}

.triple-grid > .middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.triple-grid > .right {
  display: flex;
  justify-content: left;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

img {
  user-select: none;
}

.adjacent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
