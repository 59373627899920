nav {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 2px solid var(--text-color);
}

nav,
.nav-space {
  width: 100%;
  height: 60px;
}

.nav-space {
  z-index: 1;
}
