.lifts-table {
  font-size: x-large;
  margin: 20px;
}

.lifts-table td {
  text-align: center;
}

.lifts-table th {
  text-align: left;
}

.lifts-table td,
.lifts-table th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.weight-input {
  width: 50%;
  font-size: x-large;
}

.lifts-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
