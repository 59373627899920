.log-entry-table {
  border-collapse: collapse;
  border: 3px solid var(--text-color);
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.log-entry-table th {
  color: var(--background-color);
}

.log-entry-table th,
.log-entry-table td {
  text-align: center;
  width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.log-entry-table .fail td {
  color: var(--error-color);
}

p {
  text-align: center;
}

.log-entry-table .text-input {
  font-size: inherit;
}
