.current-profile {
  border-left: 2px solid var(--edited-color);
  padding-left: 15px;
  padding-bottom: 15px;
  padding-top: 5px;
}

@media only screen and (max-width: 600px) {
  .current-profile {
    text-align: center;
    border: none;
  }
}
.current-profile .profile-name {
  font-size: 1.5em;
  margin-bottom: 10px;
  margin-top: 10px;
}

.current-profile .text-input {
  width: 200px;
  text-align: center;
}

.renaming-profile-name {
  display: flex;
  align-items: center;
}
