.training-log {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.log-entry-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.standard-icon:first-child {
  margin-left: 0;
}

.standard-icon {
  height: 40px;
  width: 40px;
  margin-left: -3px;
  margin-right: -3px;
}

@media only screen and (max-width: 380px) {
  .arrows {
    position: block;
  }
}

.arrows {
  position: sticky;
  top: 70px;
  display: flex;
  flex-direction: column;
}

.down-arrow,
.up-arrow {
  height: 50px;
  width: 50px;
  margin-left: 20px;
}

.down-arrow {
  transform: rotate(180deg);
}

.standard-icon,
.down-arrow:hover,
.up-arrow:hover {
  cursor: pointer;
}
