.cycle {
  border: solid var(--text-color) 3px;
  display: flex;
  margin: 5px;
}

.cycle-item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.cycle-item:hover {
  background-color: var(--secondary-background-color);
  cursor: pointer;
}
