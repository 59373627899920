.training-profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.training-profile-name-input {
  text-align: center;
  width: 150px;
}

.training-profile-item {
  font-size: 1.2em;
}
