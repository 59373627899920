.login-box {
  width: 500px;
  height: 600px;
  border: 3px solid var(--text-color);
  margin-top: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

.login-box h1 {
  font-size: 3em;
  margin-top: 50px;
  color: var(--edited-color);
  user-select: none;
}

.login-page {
  display: flex;
  justify-content: center;
}

.login-input {
  margin: 10px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: x-large;
  padding-right: 5px;
  text-align: left;
}

.login-button {
  padding: 10px;
  font-size: x-large;
}

.bottom {
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

.signin-button,
.login-button {
  padding-left: 20px;
  padding-right: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--background-color) inset !important;
}

.login-page .error-message {
  color: var(--error-color);
}
